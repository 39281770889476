import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import Showdown from 'showdown'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import Requirements from '../components/requirements'
import CardRelated from '../components/related'
import Cta from '../components/cta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const ProductTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  const path = location.pathname
  const converter = new Showdown.Converter()
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : post.frontmatter.requirements ? post.frontmatter.requirements[0].subtitle : ""

  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
        />

        <Hero
          class="intro-65"
          image={heroImage?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg={ post.frontmatter.headline && post.frontmatter.headline.length >= 1 ? '8' : '12' } >
              <div
                className="font-w-400 text-xs-extra-large divlink text-opening-para"
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
              />
                <div
                  className="richtext divlink mt-3"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.html) }}
                />
              </MDBCol>

              {post.frontmatter.headline &&
                post.frontmatter.headline.length >= 1 && (
                  <MDBCol lg="4" md="12" className="pl-lg-5">
                    {post.frontmatter.headline.map((headlines, index) => (
                      <div className="headline-tile mt-lg-3 item-headline" key={index} >
                        <div className="headline-icon">
                          <FontAwesomeIcon
                            icon={['fat', headlines.icon]}
                            size="2x"
                            className="fa-fw"
                          />
                        </div>
                        <div className="headline-content">
                          <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium"> {headlines.title} </h3>
                          <div className="headline-action font-alt letter-spacing-1 text-xs-medium text-small">
                            {headlines.resource ? (
                              <a href={headlines.resource.publicURL} className="effect-noline" target="_blank" rel="noopener" > {headlines.linktext} <FontAwesomeIcon icon={faChevronRight} className="ml-2" /> </a>
                            ) : headlines.link.charAt(0) != '/' ? (
                              <a href={headlines.link} className="effect-noline" target="_blank" rel="noopener" > {headlines.linktext} <FontAwesomeIcon icon={faChevronRight} className="ml-2" /> </a>
                            ) : (
                              <Link to={headlines.link} className="effect-noline" > {headlines.linktext} <FontAwesomeIcon icon={faChevronRight} className="ml-2" /> </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </MDBCol>
                )}
            </MDBRow>
          </MDBContainer>
        </section>
          
        {post.frontmatter.section.map((sections, index) => (
          <section className="bg-light-blue-prods" key={index}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="4" className="pr-5">
                  <div className="section-intro pb-5">
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                      {sections.title}
                    </h2>
                    <p className="text-medium text-gray-dark mt-3">
                      {sections.description}
                    </p>
                  </div>  
                </MDBCol>

                <MDBCol md="8">
                  <MDBRow>
                    {sections.feature.map((features, index) => (
                      <MDBCol md="6" className="pb-5 item-prods d-flex" key={index}>
                        <div className="card border-0 flex-fill card-body-transparent">
                          <div className="card-body">
                            <div className="feature-icon">
                              <FontAwesomeIcon
                                icon={['fat', features.icon]}
                                size="3x"
                              />
                            </div>
                            <p className="text-medium text-blue-dark font-w-700">
                              {features.title}
                            </p>
                            <p className="text-medium text-gray-dark">
                              {features.description}
                            </p>
                          </div>

                          {(features.link !== null && features.link !== "") && (
                            <div className="card-footer border-top-0 mt-n4">
                                <p className="text-small">
                                {(features.link.substring(0, 4) != 'http' && features.link.substring(0, 8) != '/static/') ? (
                                  <Link to={(features.link.indexOf('?') > -1 || features.link.indexOf('#') > -1 || features.link.slice(-1) == "/") ? features.link : '' + `${path}${features.link}/`} className="effect">
                                    {features.linktext}
                                  </Link>
                                ) : (
                                  <a href={features.link} className="effect" target="_blank" rel="noopener" > {features.linktext} </a>
                                )}
                                </p>
                            </div>
                          )}
                        </div>
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        ))}
             
        {post.frontmatter.requirements && post.frontmatter.requirements.length >= 1  && (
          <Requirements data={post.frontmatter.requirements} btnId={btnId} bgIndex={post.frontmatter.section.length} /> 
        )}

        {post.frontmatter.related && post.frontmatter.related.length >= 1 && (
          <section className="bg-gray-light">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mb-5">
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium">
                    Related products
                  </h3>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                {post.frontmatter.related.map((relate, index) => {
                  return (
                    <CardRelated
                      key={index}
                      collg="4"
                      colmd="6"
                      height="7.5rem"
                      type={relate.type}
                      title={relate.title}  
                      description={relate.description}
                      link={relate.link}
                    />
                  )
                })}
              </MDBRow>
            </MDBContainer>
          </section>
        )}         

        {post.frontmatter.cta && (
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        )}
      </Layout>
    </div>
  )
}
export default ProductTemplate

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark( fields: { slug: { eq: $slug } } frontmatter: { template: { eq: "products" } } ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        subtitle
        ctatext
        ctaslug        
        openingpara
        suite        
        headline {
          title
          icon
          linktext
          link
          resource {
            publicURL
          }
        }
        section {
          title
          description
          feature {
            title
            icon
            description
            linktext
            link
          }
        } 
        requirements {
          title
          subtitle
          description
        }                
        related {
          type
          title
          description
          link
        }        
        cta {
          title
          description
          linktext
          link
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`